/* eslint-disable react/destructuring-assignment, no-restricted-syntax, guard-for-in  */
import React, { useCallback } from 'react';
import {
  Table,
  Icons,
  Expandables,
  Expandable,
  SubTitle,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';
import { groupBy, get } from 'lodash';
import { DateTime } from 'luxon';

import { formatMeter } from '../../../../helpers/formatStrings';
import {
  ValidatedContractMeter,
  ValidateContractResponse,
  ValidationError,
} from '../types';
import { Relation } from '../../../../components';
import { InfoWrapped } from '../style';

import errorTranslations from './error.translation.json';
import {
  TypeIcon,
  Errors,
  Title,
  Spacer,
  InfoWrapper,
  IconWrap,
  TitleWrap,
} from './style';
import { formatType } from './formatters';

const t = (key: string, fallback: unknown = key) => {
  return get(errorTranslations.validation, key, fallback);
};

const tt = (key: string, plural: boolean) => {
  const v = t(key);

  if (Array.isArray(v)) {
    return v[plural ? 1 : 0];
  }
  return v;
};

export const columns: ColumnDefinition<any>[] = [
  {
    Header: 'Mieter',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
    type: 'string',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'contractLabel',
    type: 'string',
  },
  {
    Header: 'ZählerNr.',
    accessor: 'meterNumber',
    Cell: (props) => {
      const meterNumber = props.cell.value as any;
      return (
        <Relation icon={Icons.Meter} title={formatMeter({ meterNumber })} />
      );
    },
  },
];

const additionalErrorColumns: ColumnDefinition<{ type: string }>[] = [
  {
    Header: 'Bezeichnung',
    accessor: 'type',
    Cell: (props: any) => <TypeIcon {...props.row.original} />,
  },
];

const columnsWithError: ColumnDefinition<any>[] = [
  ...columns,
  {
    Header: 'Fehler',
    accessor: 'reason',
    id: 'errors',
    Cell: (props) => {
      const meters = props.row.original;
      return (
        <Errors>
          <div>
            mit {tt(`${mergeErrorReasons(meters.reason)}.title`, false)}
            {meters.date
              ? ` am ${DateTime.fromISO(meters.date)
                  .setZone('utc')
                  .toFormat('dd.MM.yyyy')}`
              : ''}
          </div>
        </Errors>
      );
    },
  },
];

function mergeErrorReasons(reason: string) {
  switch (reason) {
    case 'NON_INTERPOLATABLE_ACCOBJ_TYPE':
    case 'NO_METERREADING_FOR_END_OF_ACC_PERIOD':
    case 'NO_METERREADINGS_FOR_METER':
      return 'missingMeterReading';
    case 'BEGINREADING_GREATER_THAN_END_READING':
      return 'BEGINREADING_GREATER_THAN_END_READING';
    default:
      return reason;
  }
}

export function ValidateReadingsResult({
  contracts,
  additionalErrors,
}: ValidateContractResponse) {
  const succeeded = contracts.filter(
    (c) => c.meters.filter((m) => m.errors.length !== 0).length === 0,
  );
  const failed = contracts.filter(
    (c) => c.meters.filter((m) => m.errors.length !== 0).length !== 0,
  );

  const rowLink = useCallback(
    ({ row }: { row: any }) => {
      const entry = row.original as ValidatedContractMeter;
      const contract = contracts.find((c) => c.id === entry.id);
      const baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
      return `${baseURL}/contract/${entry.id}`;
    },
    [contracts],
  );

  const additionalErrorLink = useCallback(
    ({ row }: { row: any }) => {
      const entry = row.original as ValidationError;
      let contract;
      let baseURL;
      switch (entry.type) {
        case 'contract':
          contract = contracts.find((c) => c.id === entry.sourceId);
          baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
          return `${baseURL}/contract/${entry.sourceId}`;
        case 'contractcomponent':
          contract = contracts.find((c) => c.id === entry.contractId);
          baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
          return `${baseURL}/contract/${entry.contractId}/contract`;
        case 'plant':
          contract = contracts.find((c) => c.plantId === entry.sourceId);
          return `/project/${contract?.projectId}/plant/${contract?.plantId}`;
        default:
          break;
      }
    },
    [contracts],
  );

  /**
   * we basically group all the errors by the type
   */
  const groupedByError: {
    [key: string]: {
      [key: string]: any[];
    };
  } = {
    plant: {
      ...groupBy(
        additionalErrors.filter((e) => e.type === 'plant'),
        (e) => mergeErrorReasons(e.errorString),
      ),
    },
    commonContract: {
      ...groupBy(
        additionalErrors.filter((e) => e.type === 'contract'),
        (e) => mergeErrorReasons(e.errorString),
      ),
    },
    contractMeter: groupBy(
      failed
        .map(({ meters, ...contract }) =>
          meters.map(({ errors, ...meter }) =>
            errors.map((meterError) => ({
              ...meterError,
              ...contract,
              ...meter,
            })),
          ),
        )
        .flat(3),
      (e) => mergeErrorReasons(e.reason),
    ),
    contractcomponent: {
      ...groupBy(
        additionalErrors.filter((e) => e.type === 'contractcomponent'),
        (e) => mergeErrorReasons(e.errorString),
      ),
    },
  };

  const childs = [];

  for (const type in groupedByError) {
    for (const errorType in groupedByError[type]) {
      childs.push(
        <>
          {/* @ts-expect-error old Expandable */}
          <Expandable
            key={`${type}${errorType}`}
            title={
              <Title
                count={groupedByError[type][errorType].length}
                type={type}
                error={tt(
                  `${errorType}.title`,
                  groupedByError[type][errorType].length !== 1,
                )}
              />
            }
          >
            <Spacer>
              <>
                {t(errorType, false) ? (
                  <InfoWrapper>{`${(t(errorType) as any)?.desc} ${
                    (t(errorType) as any)?.resolution
                  }`}</InfoWrapper>
                ) : null}
              </>
              {type !== 'contractMeter' && (
                <Table
                  columns={additionalErrorColumns}
                  data={groupedByError[type][errorType]}
                  isLoading={false}
                  rowLink={additionalErrorLink}
                  compact
                  withAlternatingRows
                  withPagination
                  withFilter
                  openInNewTab
                />
              )}
              {type === 'contractMeter' && (
                <Table
                  columns={columnsWithError}
                  data={groupedByError[type][errorType]}
                  filterKind="Verträge"
                  isLoading={false}
                  rowLink={rowLink}
                  compact
                  withAlternatingRows
                  withPagination
                  withFilter
                  openInNewTab
                />
              )}
            </Spacer>
          </Expandable>
          ,
        </>,
      );
    }
  }

  const contractData = succeeded
    .map(({ meters, ...contract }) =>
      meters.map(({ ...meter }) => ({
        ...contract,
        ...meter,
      })),
    )
    .flat(3);

  return (
    <>
      <InfoWrapped>
        Verträge die innerhalb der Abrechnungsperiode gültig sind und noch nicht
        vollständig abgerechnet wurden.
      </InfoWrapped>
      {/* @ts-expect-error old Expandable */}
      <Expandables>
        {/* @ts-expect-error old Expandable */}
        <Expandable
          title={
            <TitleWrap>
              {succeeded.length !== 0 && (
                <>
                  <IconWrap>
                    <Icons.Checkmark size={30} color="#119142" />
                  </IconWrap>
                  {`${succeeded.length} ${
                    succeeded.length === 1 ? 'abrechenbarer' : 'abrechenbare'
                  } ${formatType('contract', succeeded.length)} gefunden`}
                </>
              )}
              {succeeded.length === 0 && (
                <>
                  <IconWrap>
                    <Icons.Warning size={30} color="#ff9800" />
                  </IconWrap>
                  Es wurden keine Verträge gefunden
                </>
              )}
            </TitleWrap>
          }
        >
          <Spacer>
            <Table
              columns={columns}
              data={contractData}
              filterKind="Verträge"
              isLoading={false}
              rowLink={rowLink}
              openInNewTab
              compact
              withAlternatingRows
              withPagination
              withFilter
            />
          </Spacer>
        </Expandable>
      </Expandables>
      {childs.length !== 0 && (
        <>
          <SubTitle>Fehler</SubTitle>
          {/* @ts-expect-error old Expandable */}
          <Expandables>{childs}</Expandables>
        </>
      )}
    </>
  );
}
