import {
  Button,
  Icons,
  maybeStr,
  Table,
  TooltipDark,
  type ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';

import { AccountMove, InvoiceState } from '../../../../../graphql-types';
import {
  formatCurrency,
  formatDate,
} from '../../../../../helpers/formatStrings';
import { assertNever } from '../../../../../helpers/type.helpers';
import { Sepa, SepaSpacer } from '../styles';
import { ToolTip } from '../../../../../components/tooltip/tooltip';

const formatInvoiceState = (i: AccountMove) => {
  if (i.state === InvoiceState.Canceled) {
    return 'storniert';
  }
  if (i.opcName !== '') {
    return 'gezahlt';
  }
  return 'unbekannt';
};

export const BookingTable: React.FC<{
  entries: AccountMove[];
  loading: boolean;
  onEditClick?: (item: AccountMove) => void;
}> = ({ entries, loading, onEditClick }) => {
  const columns: ColumnDefinition<AccountMove>[] = [
    {
      Header: 'Buchungsnummer',
      accessor: 'name',
      width: '20%',
    },
    {
      Header: 'Abschlagsdatum',
      accessor: 'bookedAt',
      width: '20%',
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: 'Zahlungsstatus',
      width: '15%',
      Cell: ({ row: { original } }) => formatInvoiceState(original),
    },
    {
      Header: 'Wert',
      accessor: 'amount',
      width: '20%',
      Cell: ({ row: { original } }) => (
        <>
          {original.sepa && <Sepa>sepa</Sepa>}
          {!original.sepa && <SepaSpacer />}
          {formatCurrency(original.amount)}
        </>
      ),
    },
    {
      Header: 'Rechnungsnummer',
      accessor: 'invoiceName',
      width: '20%',
      Cell: ({ row: { original } }) => {
        return maybeStr(original.invoiceName);
      },
    },
    ...(onEditClick !== undefined
      ? [
          {
            Header: 'Bearbeiten',
            width: '5%',
            Cell: ({ row: { original } }) => {
              const disabled =
                !!original.opcName || original.state === InvoiceState.Canceled;

              const bookingTooltipId = `booking-disabled-tooltip-${original.name}`;
              const button = (
                <Button
                  secondary
                  small
                  onClick={
                    onEditClick ? () => onEditClick(original) : undefined
                  }
                  aria-label="Bearbeiten"
                  disabled={disabled}
                  data-tip={disabled}
                  data-for={disabled && bookingTooltipId}
                >
                  <Icons.Pencil
                    size="24"
                    color={disabled ? '#ddd' : undefined}
                  />
                </Button>
              );

              if (disabled) {
                const tooltipText =
                  original.state === InvoiceState.Canceled
                    ? 'Dieser Abschlag wurde storniert und kann deshalb nicht editiert werden'
                    : 'Dieser Abschlag wurde bereits einer Abrechnung zugeordnet und kann deshalb nicht editiert werden';

                return (
                  <TooltipDark text={tooltipText} id={bookingTooltipId}>
                    {button}
                  </TooltipDark>
                );
              }

              return button;
            },
            disableSortBy: true,
          } as ColumnDefinition<AccountMove>,
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      data={entries}
      isLoading={loading}
      withAlternatingRows
    />
  );
};
