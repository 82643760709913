import {
  Button,
  ColumnDefinition,
  Expandable,
  Icons,
  Table,
  TableCells,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import styled from 'styled-components/macro';

import { handleHelpWidget } from '../../../../components/navigation/navigationHelpers';
import Relation from '../../../../components/relation';
import {
  AccountingRunValidatedContractMeter,
  AccountingRunValidationError,
} from '../../../../graphql-types';
import { formatMeter } from '../../../../helpers/formatStrings';
import { translate, translatePlural } from '../helpers/translationHelper';

import type { GroupedByErrorType } from './OverviewStep';
import { ErrorTitle, columns, mergeErrorReasons } from './OverviewStepHelper';
import { TypeIcon } from './TypeIcon';

const Spacer = styled.div`
  padding-bottom: 30px;
`;

export const renderErrorRow = (
  type: string,
  errorMessage: string,
  groupedByError: GroupedByErrorType,
  additionalErrorLink: ({
    row,
  }: TableCells<AccountingRunValidationError>) => string | undefined,
  rowLink: ({ row }: TableCells<AccountingRunValidatedContractMeter>) => string,
  isErrorForSupport?: boolean,
) => {
  const additionalErrorColumns: ColumnDefinition<{ type: string }>[] = [
    {
      Header: 'Bezeichnung',
      accessor: 'type',
      Cell: ({ row }) => <TypeIcon {...(row.original as any)} />,
    },
  ];

  const columnsWithError: ColumnDefinition<any>[] = [
    ...columns.filter((column) => column.Header !== 'ZählerNr.'),
    {
      Header: 'ZählerNr.',
      accessor: 'meterNumber',
      Cell: ({ cell }) => {
        const meterNumber = cell.value as string;
        return (
          <Relation icon={Icons.Meter} title={formatMeter({ meterNumber })} />
        );
      },
    },
    {
      Header: 'Fehler',
      accessor: 'reason',
      id: 'errors',
      Cell: ({ row }) => {
        const meters = row.original;
        return (
          <div css="margin-bottom: 10px;">
            mit{' '}
            {translatePlural(
              `${mergeErrorReasons(meters.reason)}.title`,
              false,
            )}
            {meters.date
              ? ` am ${DateTime.fromISO(meters.date)
                  .setZone('utc')
                  .toFormat('dd.MM.yyyy')}`
              : ''}
          </div>
        );
      },
    },
  ];

  return (
    /* @ts-expect-error old Expandables */
    <Expandable
      key={`${type}${errorMessage}`}
      title={
        <ErrorTitle
          count={groupedByError[type][errorMessage].length}
          type={type}
          error={translatePlural(
            `${errorMessage}.title`,
            groupedByError[type][errorMessage].length !== 1,
          )}
        />
      }
    >
      <Spacer>
        <>
          {translate(errorMessage, false) ? (
            <p css="margin-top: 0;">{`${
              (translate(errorMessage) as any)?.desc
            } ${(translate(errorMessage) as any)?.resolution}`}</p>
          ) : null}
          {type !== 'contractMeter' ? (
            <Table
              columns={additionalErrorColumns}
              data={groupedByError[type][errorMessage]}
              isLoading={false}
              rowLink={additionalErrorLink}
              compact
              withAlternatingRows
              withPagination
              withFilter
              openInNewTab
            />
          ) : (
            <Table
              columns={columnsWithError}
              data={groupedByError[type][errorMessage]}
              filterKind="Verträge"
              isLoading={false}
              rowLink={rowLink}
              compact
              withAlternatingRows
              withPagination
              withFilter
              openInNewTab
            />
          )}
        </>
      </Spacer>
      {isErrorForSupport && (
        <div css="text-align:right;">
          <Button onClick={handleHelpWidget}>Jetzt Problem melden</Button>
        </div>
      )}
    </Expandable>
  );
};
