import React from 'react';

import { Flex } from '../../../components';
import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../components/graphql-form/render';

export function CreateMeterForm({
  fieldNamePrefix = '',
  showMeterType,
  meteringDisabled = false,
}: {
  fieldNamePrefix?: string;
  showMeterType?: boolean;
  meteringDisabled?: boolean;
}) {
  return (
    <>
      <GraphqlFormInputGroup $spacingLarge>
        <Flex>
          <GraphqlFormField name={`${fieldNamePrefix}meterNumber`} />
        </Flex>
        <Flex>
          <GraphqlFormField name={`${fieldNamePrefix}meterPlace`} />
        </Flex>
      </GraphqlFormInputGroup>
      <GraphqlFormInputGroup $spacingLarge>
        {showMeterType !== false && (
          <Flex>
            <GraphqlFormField name={`${fieldNamePrefix}meterType`} />
          </Flex>
        )}
        <Flex>
          <GraphqlFormField name={`${fieldNamePrefix}converterFactor`} />
        </Flex>
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}metering`}
            disabled={meteringDisabled}
          />
        </Flex>
      </GraphqlFormInputGroup>
    </>
  );
}
