import gql from 'graphql-tag';

export default gql`
  query readContractAccounting(
    $contractId: ID!
    $contractLabel: ID!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    readContract(contractId: $contractId) {
      id
      customer {
        id
        bankAccount {
          name
          iban
          bic
          mandateReference
          signedAt
        }
        hasSepa
        isOperator
      }
    }

    readAccountMoves(
      contractLabel: $contractLabel
      startAt: $startAt
      endAt: $endAt
    ) {
      processed {
        type
        state
        amount
        bookedAt
        name
        opcName
        invoiceName
        sepa
        sepaReturn
        partialAmount
        partialPaidAt
        canceledAt
        canceledName
        paidAt
        bookings {
          type
          date
          amount
          account
        }
      }

      unprocessed {
        account
        name
        ref
        date
        amount
        journal
        sollstellungDate
        matchingNumber
      }

      vouchers {
        name
        date
        amount
        journal
        account
        ref
      }
    }
  }
`;
