import {
  AlertRetryable,
  Button,
  defaultTheme,
  Modal,
  useDialog,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import * as yup from 'yup';

import GraphQlForm from '../../../../../components/graphql-form';
import { GraphqlFormField } from '../../../../../components/graphql-form/render';
import { useCancelBookingMutation } from '../../../../../graphql-types';

type UpdateBookingModalProps = {
  contractLabel: string;
  bookingName: string;
  bookingAmount: number;
  isOpen?: boolean;
  onClose?: () => void;
  onSuccess?: () => Promise<void>;
};

export function UpdateBookingModal({
  contractLabel,
  bookingName,
  bookingAmount,
  isOpen,
  onClose,
  onSuccess,
}: UpdateBookingModalProps) {
  const { openConfirmation } = useDialog();

  const [cancelBookingMutation] = useCancelBookingMutation();

  const [cancelBookingError, setCancelBookingError] = React.useState<
    string | null
  >(null);
  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const handleCancelBookingRequest = React.useCallback(async () => {
    try {
      const cancelBookingResponse = await cancelBookingMutation({
        variables: { contractLabel, bookingName },
      });

      if (
        cancelBookingResponse.data?.cancelBooking.__typename ===
        'BookingSuccessResult'
      ) {
        onSuccess?.();
        onClose?.();
      } else {
        setCancelBookingError(
          `Die Buchung "${bookingName}" konnte nicht storniert werden. Bitte versuche es erneut.`,
        );
        setDisableSubmit(true);
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      setCancelBookingError(message);
      setDisableSubmit(true);
    }
  }, [cancelBookingMutation, contractLabel, bookingName]);

  const onCancelBooking = async () => {
    await openConfirmation({
      confirmButtonLabel: 'Stornieren',
      dangerButton: true,
      content: `Willst du die Buchung '${bookingName}' wirklich stornieren? Diese Aktion kann nicht rückgängig gemacht werden.`,
      title: `Buchung stornieren`,
      maxWidth: 'sm',
      handleRequest: handleCancelBookingRequest,
    });
  };

  return (
    <Modal
      isOpen={isOpen === true}
      onRequestClose={onClose}
      contentLabel="Contract-Update-Booking-Modal"
      title="Buchung bearbeiten"
      minWidth={600}
    >
      {cancelBookingError ? (
        <AlertRetryable
          title="Fehler beim Stornieren der Buchung"
          message={cancelBookingError}
          retryable={false}
        />
      ) : null}
      <GraphQlForm
        hideSubmit={disableSubmit}
        mutation="updateBooking"
        startInEdit
        onSuccess={() => {
          onSuccess?.();
          onClose?.();
        }}
        onAbort={onClose}
        readDocumentFields={['success']}
        validation={{
          amount: yup
            .number()
            .moreThan(0)
            .required(
              'Bitte einen Wert im folgenden Format eintragen, z.B. 34,50.',
            )
            .test(
              'unchanged-value',
              'Bitte einen Wert größer oder kleiner als der bereits eingetragene Abschlag eintragen',
              (value) => {
                return value !== bookingAmount;
              },
            ),
        }}
        variables={{
          booking: {
            name: bookingName,
            contractLabel,
          },
        }}
        values={{
          contractLabel,
          name: bookingName,
          amount: bookingAmount,
        }}
        additionalActions={
          <Button
            onClick={onCancelBooking}
            customTheme={{
              primaryColor: defaultTheme.palette.error.color,
              secondaryColor: defaultTheme.palette.error.background,
            }}
            data-testid="cancel-booking-button"
            disabled={disableSubmit}
          >
            Stornieren
          </Button>
        }
      >
        <GraphqlFormField name="name" readOnly />
        <GraphqlFormField
          name="amount"
          type="number"
          step="0.01"
          inputMode="decimal"
          appendix="€"
        />
      </GraphQlForm>
    </Modal>
  );
}
