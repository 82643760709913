import {
  Button,
  Icons,
  InvoiceState,
  Table,
  type ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';

import {
  AccountMove,
  InvoiceState as InvoiceStateType,
} from '../../../../../graphql-types';
import {
  formatCurrency,
  formatDate,
} from '../../../../../helpers/formatStrings';
import { assertNever } from '../../../../../helpers/type.helpers';
import { Sepa, SepaSpacer } from '../styles';

const formatInvoiceState = (i: AccountMove) => {
  switch (i.state) {
    case InvoiceStateType.Canceled:
      return `storniert am ${formatDate(i.canceledAt)}`;
    case InvoiceStateType.Paid:
    case InvoiceStateType.OverPaid:
      return `bezahlt am ${formatDate(i.paidAt)}`;
    case InvoiceStateType.UnderPaid:
      return `Teilzahlung am ${formatDate(i.partialPaidAt)}`;
    case InvoiceStateType.Open:
      return 'offen';
    default:
      assertNever(i.state);
  }
};

const formatAmount = (i: AccountMove): string => {
  if (
    i.state === InvoiceStateType.UnderPaid ||
    i.state === InvoiceStateType.OverPaid
  ) {
    return `${formatCurrency(i.partialAmount!)} von ${formatCurrency(
      i.amount,
    )}`;
  }

  return formatCurrency(i.amount);
};

export const InvoiceTable: React.FC<{
  entries: AccountMove[];
  loading: boolean;
  onRowClick?: (item: AccountMove) => void;
}> = ({ entries, loading, onRowClick }) => {
  const columns: ColumnDefinition<AccountMove>[] = [
    {
      Header: 'Rechnungsnummer',
      accessor: 'name',
      width: '30%',
    },
    {
      Header: 'Abrechnungsdatum',
      accessor: 'bookedAt',
      width: '25%',
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: 'Zahlungsstatus',
      width: '30%',
      Cell: ({ row: { original } }) => formatInvoiceState(original),
    },
    {
      Header: 'Wert',
      accessor: 'amount',
      width: '15%',
      Cell: ({ row: { original } }) => (
        <>
          {original.sepa && <Sepa>sepa</Sepa>}
          {!original.sepa && <SepaSpacer />}
          {formatAmount(original)}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={entries}
      isLoading={loading}
      withAlternatingRows
      onRowClick={onRowClick ? (row) => onRowClick(row.original) : undefined}
    />
  );
};
