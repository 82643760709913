import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import {
  applyPolyfills,
  defineCustomElements,
} from '@ampeersenergy/ampeers-ui-core/loader';
import type {} from 'styled-components/cssprop';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootStore from './redux/rootStore';
import { client } from './apollo-client';

const root = createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Provider store={rootStore}>
        <App />
      </Provider>
    </ApolloProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Register UI core components
// https://stenciljs.com/docs/react
applyPolyfills().then(async () => {
  await defineCustomElements();
});
