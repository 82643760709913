/* eslint-disable react/destructuring-assignment */
import {
  BooleanFilter,
  Button,
  ButtonGroup,
  ColumnDefinition,
  Filter,
  FlexRow,
  IFrameModal,
  Icons,
  Tab,
  Table,
  Tabs,
} from '@ampeersenergy/ampeers-ui-components';
import { Result } from 'neverthrow';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { ErrorMsg } from '../../../../components';
import { useHasRole } from '../../../../components/useHasRole';
import {
  InvoiceDraft,
  InvoiceDraftTupleInput,
} from '../../../../graphql-types';
import { buildDocumentUrl } from '../../../../helpers/buildDocumentUrl';
import { formatString } from '../../../../helpers/formatStrings';

import { InvoicePreview } from './InvoicePreview';
import { useInvoiceDrafts } from './useInvoiceDrafts';

const PullRight = styled(FlexRow)`
  margin: 20px 0;
  align-items: center;
  justify-content: flex-end;
`;

const Hint = styled.div`
  color: ${(props) => props.theme.palette.textMuted};
  font-size: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

const capitalize = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

function buildTabTitle(label: string, amount?: number, loading?: boolean) {
  return [
    !loading && amount ? amount : '',
    `${amount && amount > 0 ? label : capitalize(label)}${
      amount !== 1 ? 'en' : ''
    }`,
  ]
    .join(' ')
    .trim();
}

const booleanFilters: BooleanFilter[] = [
  {
    id: 'documentDeliveryMethod',
    value: 'post',
    label: (active) => (
      <>
        Nur Postversand
        <Icons.Mail size={20} color={active ? '#fff' : 'primary'} />
      </>
    ),
  },
];

interface InvoiceDraftsTableProps {
  confirmed: InvoiceDraft[];
  unconfirmed: InvoiceDraft[];
  invoiceAction: (
    actionType: 'accept' | 'reject',
    draftIds: InvoiceDraftTupleInput[],
  ) => Promise<Result<void, Error>>;
  isLoading: boolean;
  previewInvoice?: (contractLabel: string) => Promise<Result<string, string>>;
  invoiceProcessing?: boolean;
  accountingName: string;
  workflowName?: string;
  retryDocumentCreation: () => Promise<void>;
}

export function InvoiceDraftsTable({
  confirmed,
  unconfirmed,
  invoiceAction,
  isLoading,
  previewInvoice,
  invoiceProcessing,
  accountingName,
  workflowName,
  retryDocumentCreation,
}: InvoiceDraftsTableProps) {
  const { hasRole: operationsRole } = useHasRole('ae-operations');

  const {
    downloadInvoices,
    downloadAccountingDetailReport,
    reportIsLoading,
    downloadIsLoading,
  } = useInvoiceDrafts(accountingName, workflowName);

  const [error, setError] = useState<Error | undefined>();
  const [confirmedError, confirmedSetError] = useState<Error | undefined>();
  const [, canceledSetError] = useState<Error | undefined>();
  const [selectedUnconfirmedRows, setSelectedUnconfirmedRows] = useState<
    string[]
  >([]);
  const [selectedRowsForDownload, setSelectedRowsForDownload] = useState<
    string[]
  >([]);
  const [filters] = React.useState<Filter[]>([]);

  const [documentUrl, setDocumentUrl] = React.useState<string | undefined>();

  const handleViewInvoiceDocument = useCallback((fileUrl: string) => {
    const url = new URL(buildDocumentUrl(fileUrl));
    url.searchParams.set('attachment', 'false');

    setDocumentUrl(url.toString());
  }, []);

  const documentErrorsExist =
    confirmed.filter((c) => !c.invoiceDocumentUrl).length > 0;

  const unconfirmedColumns: ColumnDefinition<InvoiceDraft>[] = useMemo(
    () => [
      {
        Header: 'VertragsNr',
        accessor: 'contractId',
      },
      {
        Header: 'Name',
        accessor: 'customerName',
        type: 'string',
      },
      {
        Header: 'Rechnungsdatum',
        accessor: 'dueDate',
        type: 'date',
      },
      {
        Header: 'Betrag',
        accessor: 'amount',
        type: 'currency',
      },
      {
        Header: 'Datei',
        accessor: 'id',
        disableSortBy: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: React.memo(({ row }) => (
          <InvoicePreview
            invoiceDraft={row.original}
            previewInvoice={previewInvoice}
            handleViewInvoiceDocument={handleViewInvoiceDocument}
            hasOperationRole={operationsRole}
          />
        )),
        filterable: false,
      },
    ],
    [handleViewInvoiceDocument, operationsRole, previewInvoice],
  );

  const confirmedColumns: ColumnDefinition<InvoiceDraft>[] = useMemo(() => {
    const invoiceNumberCol: ColumnDefinition<InvoiceDraft> = {
      Header: 'RechnungsNr',
      accessor: 'invoiceId',
      Cell: (props: any) => {
        return formatString(props.row.original.invoiceId);
      },
    };
    const deliveryColumn: ColumnDefinition<InvoiceDraft> = {
      Header: 'Zustellung',
      accessor: 'documentDeliveryMethod',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: React.memo(({ row }) => {
        const { documentDeliveryMethod } = row.original;

        if (documentDeliveryMethod === 'email') {
          return (
            <IconWrapper>
              <Icons.Email color="primary" size={24} /> E-Mail
            </IconWrapper>
          );
        }
        if (documentDeliveryMethod === 'post') {
          return (
            <IconWrapper>
              <Icons.Mail color="primary" size={24} /> Post
            </IconWrapper>
          );
        }
        return null;
      }),
    };

    // Add deliveryColumn at the second to last position of the array
    const columns = [invoiceNumberCol, ...unconfirmedColumns];
    return [
      ...columns.slice(0, columns.length - 1),
      deliveryColumn,
      ...columns.slice(columns.length - 1),
    ];
  }, [unconfirmedColumns]);

  const _invoiceAction = React.useCallback(
    async (mutationType: string) => {
      const invoiceDraftsTuple: InvoiceDraftTupleInput[] =
        selectedUnconfirmedRows.map((rowId) => ({
          contractLabel: unconfirmed[+rowId].contractId,
          invoiceDraftId: unconfirmed[+rowId].id,
        }));

      const result = await invoiceAction(
        mutationType as any,
        invoiceDraftsTuple,
      );

      if (result.isErr()) {
        setError(result.error);
        confirmedSetError(result.error);
        canceledSetError(result.error);
      }
    },
    [
      invoiceAction,
      setError,
      confirmedSetError,
      canceledSetError,
      selectedUnconfirmedRows,
      unconfirmed,
    ],
  );

  const retryDocumentCreationCallback = React.useCallback(async () => {
    await retryDocumentCreation();
  }, [retryDocumentCreation]);

  const onDownloadInvoices = React.useCallback(() => {
    const draftsIds = selectedRowsForDownload.map(
      (rowId) => confirmed[parseInt(rowId, 10)].id,
    );

    downloadInvoices(draftsIds);
  }, [selectedRowsForDownload, downloadInvoices, confirmed]);

  return (
    <>
      <Tabs>
        {/* @ts-expect-error old Tab */}
        <Tab
          title={buildTabTitle(
            'ungeprüfte Rechnung',
            unconfirmed?.length,
            isLoading,
          )}
        >
          <PullRight>
            <ButtonGroup>
              <Button
                data-testid="reject-settlement-button"
                id="reject-settlement-button"
                secondary
                onClick={() => _invoiceAction('reject')}
                disabled={
                  invoiceProcessing || selectedUnconfirmedRows.length === 0
                }
              >
                Rechnung löschen
              </Button>
              <Button
                data-testid="release-settlement-button"
                id="release-settlement-button"
                onClick={() => _invoiceAction('accept')}
                disabled={selectedUnconfirmedRows.length === 0}
                isLoading={invoiceProcessing}
              >
                Rechnung Freigeben
              </Button>
            </ButtonGroup>
          </PullRight>
          {error && <ErrorMsg error={error} />}
          <Table
            disabled={invoiceProcessing}
            columns={unconfirmedColumns}
            data={unconfirmed}
            filterKind="ungeprüften Rechnungen"
            multiSelect
            onSelect={setSelectedUnconfirmedRows}
            isLoading={isLoading}
            filterState={filters}
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
          />
          <Hint>
            Kundinnen und Kunden mit E-Mail Kommunikationsweise erhalten Ihre
            Abrechnungen nach der Freigabe automatisch
          </Hint>
        </Tab>
        {/* @ts-expect-error old Tab */}
        <Tab
          title={buildTabTitle(
            'freigegebene Rechnung',
            confirmed?.length,
            isLoading,
          )}
        >
          <PullRight css="gap: 0.5rem;">
            <Button
              id="invoice-download-dropdown"
              onClick={downloadAccountingDetailReport}
              disabled={invoiceProcessing}
              isLoading={reportIsLoading}
              secondary
            >
              Export Rechnungsdetails
            </Button>
            {operationsRole && documentErrorsExist && (
              <Button
                disabled={invoiceProcessing}
                secondary
                onClick={retryDocumentCreationCallback}
              >
                Dokumentenerstellung erneut anstoßen
              </Button>
            )}
            <Button
              id="invoice-download-dropdown"
              onClick={onDownloadInvoices}
              disabled={
                invoiceProcessing || selectedRowsForDownload.length === 0
              }
              isLoading={downloadIsLoading}
            >
              Herunterladen
              {selectedRowsForDownload.length > 0 &&
                ` (${selectedRowsForDownload.length})`}
            </Button>
          </PullRight>
          {confirmedError && <ErrorMsg error={confirmedError} />}
          <Table
            disabled={invoiceProcessing}
            columns={confirmedColumns}
            data={confirmed}
            filterKind="freigegebenen Rechnungen"
            isLoading={isLoading}
            filterState={filters}
            multiSelect
            onSelect={setSelectedRowsForDownload}
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withBooleanFilter={booleanFilters}
          />
        </Tab>
      </Tabs>
      {documentUrl && (
        <IFrameModal
          src={documentUrl}
          contentLabel="Zeige Rechnung"
          closeModal={() => setDocumentUrl(undefined)}
          title="Rechnung"
        />
      )}
    </>
  );
}
